import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { merge as _merge } from 'lodash-es';
import { DynamicSaveChangesButtonComponent } from '../dynamic-save-changes-button/dynamic-save-changes-button.component';
import { Router } from '@angular/router';

@Component({
    selector: 'dynamic-action-button',
    templateUrl: './dynamic-action-button.component.html',
    styleUrls: ['./dynamic-action-button.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    animations: [appModuleAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicActionButtonComponent extends AppComponentBase {
    static id = "DynamicActionButtonComponent";
    @ViewChild(DynamicSaveChangesButtonComponent) dynamicSaveChangesButton: DynamicSaveChangesButtonComponent;

    @Input() actionButtonStructure: any;
    @Input() dynamicGridComponents: any;
    @Input() dynamicFormComponents: any;
    @Input() disabled: boolean = false;
    @Input() pageName: string;
    @Input() dataSourceName: string;

    constructor(
        injector: Injector,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnChanges() {
    }

    ngOnInit() {
    }

    ngDoCheck() {
    }

    ngAfterContentInit() {
    }

    ngAfterContentChecked() {
    }

    ngAfterViewInit() {
    }

    ngAfterViewChecked() {
    }

    ngOnDestroy() {
    }

    goToLink() {
        if (this.actionButtonStructure.componentSettings.dynamicPageId !== "") {
            if (this.isGUID(this.actionButtonStructure.componentSettings.dynamicPageId)) {
                this.router.navigate(['app/main/dynamic-page/' + this.actionButtonStructure.componentSettings.dynamicPageId]);
            }
            else {
                this.router.navigate(['app/main/' + this.actionButtonStructure.componentSettings.dynamicPageId]);
            }
        }
    }

    search() {
        let list = [];
        this.dynamicFormComponents.forEach(dynamicFormComponent => {
            list.push(dynamicFormComponent.dynamicForm.value);
        });

        this.dynamicGridComponents.forEach(dynamicGridComponent => {
            dynamicGridComponent.getDynamicDataItems(list, undefined, true);
        });
    }

    getComponentName(): string {
        return "DynamicActionButtonComponent";
    }
}
