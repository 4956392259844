import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DynamicDataServiceProxy, SaveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'dynamic-upload-button',
    templateUrl: './dynamic-upload-button.component.html',
    styleUrls: ['./dynamic-upload-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicUploadButtonComponent extends AppComponentBase {

    @Input() actionButtonStructure: any;
    @Input() dynamicGridComponents: any;
    selectedFile: any;
    fileToUpload: any;
    currentFile: any;
    saving = false;

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        
    }

    downloadUserTemplate() {
        console.log("Download");
        var csv = "data:application/csv, " + encodeURIComponent("firstname,lastname,username,email\r\n");

        var x = document.createElement("a");
        x.setAttribute("href", csv);
        x.setAttribute("download", "upload-user-template.csv");
        x.style.display = "none";
        document.body.appendChild(x);
        x.click();
    }

    browseFiles() {
        document.getElementById("getFile").click();
    }

    sendEmail() {
        let request = new SaveDynamicDataRequest();
        request.dataSourceName = "SendEmailToUsers";
        request.action = "update";
        request.data = {};
        request.data["clientDomain"] = this.appSession.tenancyName;
        request.data["usernames"] = [];

        if (this.dynamicGridComponents.length > 0) {
            this.dynamicGridComponents.forEach(dynamicGridComponent => {
                if (dynamicGridComponent.selectedRows.length > 0) {
                    for (var selectedRow of dynamicGridComponent.selectedRows) {
                        request.data["usernames"].push(selectedRow.dataItem['sls_user$username']);
                    }
                }
            })
        }

        if (request.data["usernames"].length > 0) {
            this._dynamicDataServiceProxy.saveDynamicData(request)
                .pipe(
                    finalize(() => {
                        this.cd.markForCheck();
                    })
                ).subscribe(result => {
                    if (result.data.items[0].isSuccess) {
                        this.message.success(this.l("SuccessfullySubmitted"), this.l("SendEmailToSelectedUsers"), { isHtml: true });
                    }
                    else {
                        this.message.error(this.l("EmailFailed"), this.l("SendEmailToSelectedUsers"), { isHtml: true });
                    }
                });
        }
        else {
            this.message.warn(this.l("NoUsersSelected"), this.l("SendEmailToSelectedUsers"), { isHtml: true });
        }
    }

    isCSVFile(file) {
        return file.type === 'text/csv' || file.name.toLowerCase().endsWith('.csv');
    }

    uploadCall() {
        if (this.isCSVFile(this.currentFile)) {
            let request = new SaveDynamicDataRequest();
            request.dataSourceName = "UploadUsers";
            request.action = "update";
            request.data = {};

            request.data["fileDate"] = this.currentFile.lastModified;
            request.data["fileName"] = this.currentFile.name;

            this.fileToUpload = atob(this.fileToUpload.split(",")[1]);
            this.fileToUpload = this.fileToUpload.split("\r\n");

            request.data["lines"] = this.fileToUpload;

            this._dynamicDataServiceProxy.saveDynamicData(request)
                .pipe(
                    finalize(() => {
                        this.saving = false;
                        this.cd.markForCheck();
                    })
                ).subscribe(result => {
                    if (result.data.items[0].isSuccess) {
                        this.dynamicGridComponents[0].getDynamicDataItems();
                        this.message.success(this.l("SuccessfullyUploaded"), this.l("UploadNewUsers"), { isHtml: true });
                    }
                    else {
                        if (result.data.items[0].validationMessage.length > 0) {
                            this.message.error(result.data.items[0].validationMessage[0], this.l('UploadNewUsers'), { isHtml: true });
                        }
                        else {
                            this.message.error(this.l("ImportUsersUploadFailed"), this.l('UploadNewUsers'), { isHtml: true });
                        }
                    }
                });
        }
        else {
            this.message.warn(this.l("UploadValidCsvFile"), this.l("UploadNewUsers"), { isHtml: true });
            this.saving = false;
            this.cd.markForCheck();
        }
    }

    uploadFile() {
        this.saving = true;
        const reader = new FileReader();

        let input: any = document.getElementById("getFile");
        this.currentFile = input.files[0];

        reader.onload = () => {
            this.fileToUpload = reader.result;
            this.uploadCall();
        }

        reader.readAsDataURL(input.files[0]);
    }
}
