import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { merge as _merge, uniqBy as _uniqBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { FormGroup } from '@angular/forms';
import { CustomTemplate } from '@app/shared/common/dynamic-components/dynamic-grid/custom-template';
import { DynamicDataResponse, DynamicDataServiceProxy, SaveDynamicDataRequest, SaveTransactionTypeTreeRequest } from '@shared/service-proxies/service-proxies';
import { catchError, finalize } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'dynamic-save-changes-button',
    templateUrl: './dynamic-save-changes-button.component.html',
    styleUrls: ['./dynamic-save-changes-button.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    animations: [appModuleAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicSaveChangesButtonComponent extends AppComponentBase {
    @Input() dynamicGridComponents: any;
    @Input() dynamicFormComponents: any;

    @Input() actionButtonStructure: any;
    @Input() gridStructures: any;
    @Input() formStructures: any;
    @Input() pageName: string;
    @Input() disabled: boolean = false;
    @Input() dataSourceName: string;
    convertedPageName: string;
    gridName: string;
    gridStructure: any;
    formStructure: any;
    dataAsOf: DateTime;
    selectedItemIds: string[] = [];
    editedRowIndex: number;
    customTemplates: CustomTemplate[] = [];
    @ViewChild('customBillNumber') public customBillNumber: TemplateRef<any>;
    @ViewChild('customPayCheckBox') public customPayCheckBox: TemplateRef<any>;
    @ViewChild('customMenuIcon') public customMenuIcon: TemplateRef<any>;
    formGroup: FormGroup;
    parentItem: any;
    gridComponents: any[] = [];
    formComponents: any[] = [];
    retrieveDataBy: string;
    saving: boolean = false;
    selectedTransactionTypeId: string;
    userNotified: boolean = false;
    originalDisabledValue: boolean = false;

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy,
        private _activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        super(injector);
    }

    ngOnChanges() {
    }

    ngOnInit() {
        this.originalDisabledValue = this.disabled;
    }

    ngDoCheck() {
    }

    ngAfterContentInit() {
    }

    ngAfterContentChecked() {
    }

    ngAfterViewInit() {
        this.addCustomTemplates();
    }

    ngAfterViewChecked() {
    }

    ngOnDestroy() {
    }

    addCustomTemplates() {
    }

    confirmPublishChanges() {
        abp.event.trigger("app.toggleTabAccessibility", false);
        this.saving = true;

        this.message.confirm(
            this.l("AreYouSureYouWantToApplyChanges"),
            this.l(this.actionButtonStructure.componentSettings.actionButtonLabelKey),
            isConfirmed => {
                if (isConfirmed) {
                    this.publishChanges();
                }
                else {
                    this.saving = false;
                    this.cd.markForCheck();
                }
            },
        );
    }

    saveDynamicData(request, isSaveDynamicDataApi = false): Observable<DynamicDataResponse> {
        if (isSaveDynamicDataApi) {
            return this._dynamicDataServiceProxy.saveDynamicDataApi(request);
        }
        else {
            return this._dynamicDataServiceProxy.saveDynamicData(request);
        }
    }

    goToLink(data, isStep4 = false) {
        if (isStep4) {
            this.router.navigate(['app/main/intacct-setup-process']);
        }

        if (this.actionButtonStructure.componentSettings.dynamicPageId !== "") {
            if (!this.isGUID(this.actionButtonStructure.componentSettings.dynamicPageId)) {
                this.router.navigate(['app/main/' + this.actionButtonStructure.componentSettings.dynamicPageId]);
            }
            else if (this.appSession.user !== undefined && window.location.pathname.split("/")[1] !== "dynamic-page") {
                this.router.navigate(['app/main/dynamic-page/' + this.actionButtonStructure.componentSettings.dynamicPageId]);
            }
            else {
                localStorage.setItem("redirectData", JSON.stringify(data));

                this.router.navigate(['dynamic-page/' + this.actionButtonStructure.componentSettings.dynamicPageId], { queryParams: { emailAddress: data.formComponents[0].value['sls_user_in_process$email'] } })
            }
        }
    }

    hasDropDownTree(): boolean {
        const dropDownTreeKeys = ['NonChartOfAccountsFields', 'HeaderFieldsDefinition', 'DistributionGrid'];
        if (this.dynamicGridComponents) {
            return dropDownTreeKeys.includes(this.dynamicGridComponents[0]?.pageLabelKey);
        } else {
            return false;
        }
    }

    savingTreeView(): boolean {
        const treeViewKeys = ['TransactionCategoriesAndTypes', 'ExceptionAndResolutionTypeRelationships'];
        if (this.dynamicFormComponents && this.dynamicFormComponents.length > 0) {
            return treeViewKeys.includes(this.dynamicFormComponents[0].formStructure.componentSettings.formLabelKey);
        } else {
            return false;
        }
    }

    hasTreeView(): boolean {
        const treeViewKeys = ['TransactionCategoriesAndTypes', 'OrganizationalUnitsAndEntityRelationships', 'ExceptionAndResolutionTypeRelationships', 'TransactionNumberFormat'];
        if (this.dynamicFormComponents) {
            return treeViewKeys.includes(this.dynamicFormComponents[0].formStructure.componentSettings.formLabelKey);
        } else {
            return false;
        }
    }

    setTransactionTypeId(request: any): void {
        this.selectedTransactionTypeId = request.data?.gridComponents[0]?.addedAndUpdatedItems[0]?.dev_non_chart_of_account_field$dev_transaction_type_id;
        if (this.selectedTransactionTypeId !== null && this.selectedTransactionTypeId !== undefined) {
            request.data.gridComponents[0].addedAndUpdatedItems[0].dev_non_chart_of_account_field$dev_transaction_type_id = request.data.formComponents[0]?.value?.sls_transaction_type$sls_transaction_type_id?.id;
        }
    }

    removeFormComponentsDataForTheBLValidationToPushTrough(request): void {
        delete request.data.formComponents;
    }

    publishChanges(isFromDialog: boolean = false) {
        let request = new SaveDynamicDataRequest();
        let data = {};

        // Handler for specified component/s applied in the dynamic action button
        //let actionButtonComponentSettings = this.actionButtonStructure.componentSettings;
        //if (actionButtonComponentSettings.component === AppConsts.componentType.Grid &&
        //    this.dynamicGridComponents.findIndex(x => x.gridStructure.componentSettings.gridId === actionButtonComponentSettings.componentId) > -1) {
        //    let gridComponents: any[] = [];
        //    let associatedGrid = this.dynamicGridComponents.find(x => x.gridStructure.componentSettings.gridId === actionButtonComponentSettings.componentId);

        //    console.log(associatedGrid);

        //    const dynamicGridComponentHasItems = associatedGrid.items.length > 0;

        //    if (associatedGrid !== undefined && dynamicGridComponentHasItems) {
        //        let grid = {};

        //        //NOTE: Assign parentId
        //        if (this.retrieveDataBy !== undefined) {
        //            if (associatedGrid.items !== undefined) {
        //                associatedGrid.items.map(x => {
        //                    x.parentId = this.retrieveDataBy;
        //                });
        //            }
        //        }

        //        //TODO: Add support for multiple grid returnedData
        //        grid['gridName'] = associatedGrid.gridStructureDataSourceName;
        //        grid['gridDataDataSourceName'] = associatedGrid.gridDataDataSourceName;
        //        grid['gridStructure'] = associatedGrid.gridStructure;

        //        grid['items'] = associatedGrid.items;
        //        grid['deletedIds'] = associatedGrid.deletedIds;

        //        grid['addedAndUpdatedItems'] = associatedGrid.addedItems.concat(associatedGrid.updatedItems);

        //        gridComponents.push(grid);
        //        data['gridComponents'] = gridComponents;
        //    }
        //}
        //else if (actionButtonComponentSettings.component === AppConsts.componentType.Form &&
        //    this.dynamicFormComponents.findIndex(x => x.formStructure.componentSettings.formId === actionButtonComponentSettings.componentId) > -1) {

        //    let formComponents: any[] = [];
        //    let associatedForm = this.dynamicFormComponents.find(x => x.formStructure.componentSettings.formId === actionButtonComponentSettings.componentId);

        //    console.log(associatedForm);
        //    if (associatedForm !== undefined) {
        //        let form = {};

        //        form['parentId'] = this.retrieveDataBy;
        //        form['formStructure'] = associatedForm.formStructure;
        //        form['formName'] = associatedForm.formStructureDataSourceName;
        //        form['value'] = associatedForm.dynamicForm?.value;

        //        formComponents.push(form);
        //        data['formComponents'] = formComponents;
        //    }
        //}

        //NOTE: loop multiple grid components
        if (this.dynamicGridComponents.length > 0) {
            let gridComponents: any[] = [];

            this.dynamicGridComponents.filter(x => x.disableActionButton === false).forEach(dynamicGridComponent => {
                dynamicGridComponent.gridDependencyLoading = true;
                //TODO: Temporary check because two grid components are registered even though the UI only shows 1. This should be fixed because if not, it will not recognize the changes if the user tries to delete all records on the grid.
                //const dynamicGridComponentHasItems = dynamicGridComponent.items.length > 0;

                if (dynamicGridComponent !== undefined /*&& dynamicGridComponentHasItems*/) {
                    let grid = {};

                    //NOTE: Assign parentId
                    if (this.retrieveDataBy !== undefined) {
                        if (dynamicGridComponent.items !== undefined) {
                            dynamicGridComponent.items.map(x => {
                                x.parentId = this.retrieveDataBy;
                            });
                        }
                    }

                    //TODO: Add support for multiple grid returnedData
                    grid['gridName'] = dynamicGridComponent.gridStructureDataSourceName;
                    grid['gridDataDataSourceName'] = dynamicGridComponent.gridDataDataSourceName;
                    grid['gridStructure'] = dynamicGridComponent.gridStructure;

                    grid['items'] = dynamicGridComponent.items;
                    grid['deletedIds'] = dynamicGridComponent.deletedIds;

                    const processDateFields = (items: any[]) => {
                        items.forEach(item => {
                            Object.keys(item).forEach(key => {
                                if (key.toLowerCase().includes("date") && !key.includes("Id")) {
                                    item[key] = item[key]
                                        ? (item[key] instanceof Date && typeof item[key].toISOString === 'function')
                                            ? DateTime.fromISO(item[key].toISOString()).setZone("UTC", { keepLocalTime: true })
                                            : item[key]
                                        : undefined;
                                }
                            });
                        });
                    };

                    processDateFields(dynamicGridComponent.addedItems);
                    processDateFields(dynamicGridComponent.updatedItems);

                    grid['addedAndUpdatedItems'] = dynamicGridComponent.addedItems.concat(dynamicGridComponent.updatedItems);

                    gridComponents.push(grid);
                }
            });

            data['gridComponents'] = gridComponents;
        }

        //NOTE: loop multiple form components
        if (this.dynamicFormComponents.length > 0 && !this.hasTreeView()) {
            let formComponents: any[] = [];

            this.dynamicFormComponents.forEach(dynamicFormComponent => {

                if (dynamicFormComponent !== undefined) {
                    let form = {};

                    if (dynamicFormComponent.dynamicForm !== undefined) {
                        Object.keys(dynamicFormComponent.dynamicForm?.value).forEach(key => {
                            if (dynamicFormComponent.dynamicForm.value[key] instanceof Date) {
                                dynamicFormComponent.dynamicForm.value[key] =
                                    DateTime.fromISO(dynamicFormComponent.dynamicForm.value[key].toISOString()).setZone("UTC",
                                        { keepLocalTime: true });
                            }
                        });
                    }

                    form['parentId'] = this.retrieveDataBy;
                    form['formStructure'] = dynamicFormComponent.formStructure;
                    form['formName'] = dynamicFormComponent.formStructureDataSourceName;
                    form['value'] = dynamicFormComponent.dynamicForm?.value;

                    if (dynamicFormComponent.deletedTreeViewIds.length > 0) {
                        data['deletedTreeViewIds'] = dynamicFormComponent.deletedTreeViewIds;
                    }

                    if (dynamicFormComponent.treeViewData !== undefined) {
                        data['treeViewData'] = dynamicFormComponent.treeViewData;
                    }

                    formComponents.push(form);
                }
            });

            data['formComponents'] = formComponents;
        }

        if (this._activatedRoute.snapshot["_urlSegment"].segments[4]?.path === "00000000-0000-0000-0000-000000000000") {
            data['parentId'] = "";
        }
        else {
            data['parentId'] = this._activatedRoute.snapshot["_urlSegment"].segments[4]?.path;
        }

        if (data['pageId'] === undefined) {
            data['pageId'] = this.actionButtonStructure.componentSettings.pageId;
        }

        request.action = "save";
        request.dataSourceName = this.dataSourceName;
        let isSaveDynamicDataApi = false;

        if (this.permission.isGranted('Pages.Administration.Host.Settings')) {
            data['clientId'] = this.clientAndErpService.clientId;
            data['erpId'] = this.clientAndErpService.erpId;
        }
        else {
            data['clientId'] = undefined;
            data['erpId'] = 888; //NOTE: Use erpId in server
            isSaveDynamicDataApi = true;
        }

        request.data = data;

        if (this.hasDropDownTree()) {
            this.setTransactionTypeId(request);
            this.removeFormComponentsDataForTheBLValidationToPushTrough(request);
        }

        if (this.savingTreeView()) {
            var treeViewSaveRequest = new SaveTransactionTypeTreeRequest();
            treeViewSaveRequest.entityId = data['clientId'];
            treeViewSaveRequest.deletedIds = data['deletedTreeViewIds'];
            treeViewSaveRequest.transactionTypeTree = data['treeViewData'];
            console.log(treeViewSaveRequest);
            this._dynamicDataServiceProxy.saveTransactionTypeTree(treeViewSaveRequest)
                .pipe(
                    catchError(() => {
                        return of(undefined);
                    }),
                    finalize(() => {
                        this.saving = false;
                        this.gridIsLoading(false);

                        this.cd.markForCheck();
                    })
                ).subscribe(response => {
                    if (response.result) {
                        this.dynamicFormComponents.forEach(dynamicFormComponent => {
                            dynamicFormComponent.treeViewData = response.transactionTypeTree;
                        });
                    }
                });
            return;
        }

        // NOTE: For Dynamic Pages. Dynamic Maintenance Pages is in dynamic-page.component.ts, line 678.
        // console.log("request.data.formComponents[0].value");
        // console.log(request.data.formComponents[0].value);
        // this.gridIsLoading(false);
        // return;

        this.saveDynamicData(request, isSaveDynamicDataApi)
            .pipe(
                catchError(() => {
                    return of(undefined);
                }),
                finalize(() => {
                    this.saving = false;
                    this.gridIsLoading(false);

                    // Implemented this for loading specific grids inside a page. See filtering when looping through dynamic grid components during the building of request.
                    this.dynamicGridComponents.forEach(dynamicGridComponent => {
                        dynamicGridComponent.gridDependencyLoading = false;
                    });

                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (result !== undefined) {
                    if (result.data.asyncItems.length > 0) {
                        this.dynamicGridComponents.forEach(dynamicGridComponent => {
                            if (dynamicGridComponent !== undefined) {
                                const filteredItems = dynamicGridComponent.items.filter(x => x.id !== "");
                                dynamicGridComponent.items = filteredItems;

                                if (result.data.asyncItems.filter(x => x.componentId === dynamicGridComponent.gridId).length > 0) {
                                    dynamicGridComponent.items = [];
                                }

                                result.data.asyncItems.forEach(returnedItem => {
                                    for (var key in returnedItem) {
                                        if (key.toLowerCase().includes("date") == true && key.includes("Id") == false) {
                                            returnedItem[key] = returnedItem[key] ? new Date(returnedItem[key]) : <any>undefined;
                                        }
                                    }

                                    returnedItem = this.formatItemTextToComboBoxData(returnedItem, dynamicGridComponent.originalComboBoxData);
                                    returnedItem = this.formatItemTextToBoolean(returnedItem, dynamicGridComponent.gridStructure);

                                    if (returnedItem.componentId === dynamicGridComponent.gridId) {
                                        dynamicGridComponent.items.unshift(returnedItem);
                                    }
                                    else if (returnedItem.componentId === undefined) {
                                        //NOTE: Support for concurrency. Replace item from dynamicGridComponent.items if the item is there
                                        const itemsIndex = dynamicGridComponent.items.findIndex(x => x.id === returnedItem.id);
                                        const itemExists = itemsIndex !== -1;

                                        if (itemExists) {
                                            dynamicGridComponent.items.splice(itemsIndex, 1);
                                        }
                                        dynamicGridComponent.items.unshift(returnedItem);
                                    }

                                    dynamicGridComponent.addedItems = [];
                                });

                                dynamicGridComponent.disableActionButton = true;

                                dynamicGridComponent.updatedItems = [];

                                dynamicGridComponent.populateGrid(true);
                            }
                        });

                        this.dynamicGridComponents.forEach(dynamicGridComponent => {
                            dynamicGridComponent.items.forEach(item => {
                                if (item.componentId !== undefined) {
                                    delete item.componentId;
                                }
                            })
                        });

                        this.dynamicGridComponents.forEach(dynamicGridComponent => {
                            if (dynamicGridComponent.gridStructure?.componentSettings !== undefined && dynamicGridComponent.gridStructure?.componentSettings?.dependentDynamicGridId) {
                                abp.event.trigger('app.grid_cell_' + dynamicGridComponent.gridStructure.componentSettings.dependentDynamicGridId + '.onClick', dynamicGridComponent.items, dynamicGridComponent.gridStructure);
                            }
                        });
                    }

                    // This will make sure that data will be retrieved with the latest information.
                    this.dynamicGridComponents.forEach(dynamicGridComponent => {
                        dynamicGridComponent.getDynamicDataItems();
                        // Ensure button stays disabled after data refresh
                        dynamicGridComponent.disableActionButton = true;
                    });

                    this.dynamicFormComponents.forEach(dynamicFormComponent => {
                        if (dynamicFormComponent !== undefined) {
                            dynamicFormComponent.dynamicForm?.markAsPristine();
                            dynamicFormComponent.dynamicForm?.markAsUntouched();
                            if (dynamicFormComponent.dynamicForm?.controls) {
                                Object.keys(dynamicFormComponent.dynamicForm.controls).forEach(key => {
                                    const control = dynamicFormComponent.dynamicForm?.get(key);
                                    control?.markAsPristine();
                                    control?.markAsUntouched();
                                });
                            }
                        }
                    });

                    // Step 4 Failure is for checking for any values in the grid in the Client Signup Process
                    var step4Failure: boolean = false;
                    if (result.data.validationMessage !== undefined && result.data.validationMessage !== null) {
                        this.notify.warn(result.data.validationMessage);
                    }
                    else {
                        if (this.actionButtonStructure.componentSettings.dynamicPageId === "") {
                            this.notify.success(this.l("ChangesSuccessfullyApplied"));
                        }

                        // A new activation code will be generated as the user is routed to the Email Activation page for the Client Signup Process.
                        const isStep1 = this.dynamicFormComponents.findIndex(x => x.formStructure.componentSettings.formName === 'NewClientSignupStep1') >= 0;
                        const isUserDetail = this.dynamicFormComponents[0].formStructure.componentSettings.formLabelKey.includes("UserDetails");
                        if (isStep1) {
                            this.step1Activation(this.dynamicFormComponents[0].dynamicForm.controls['sls_user_in_process$email'].value, data);
                        }
                        else if (isUserDetail) {
                            const isNewRecord = this._activatedRoute.snapshot.params['detailPageId'] === "00000000-0000-0000-0000-000000000000";
                            if (result.data.asyncItems.length > 0 && isNewRecord) {
                                this.router.navigateByUrl(this.router.url.replace(this._activatedRoute.snapshot.params['detailPageId'], result.data.asyncItems[0].id));
                            }

                            abp.event.trigger("app.moveToNextTab", 1);
                        }
                        else {
                            // Don't go to link if Intacct is not selected in the grid of Client Signup Process Step 4
                            var shouldRedirect: boolean = true;
                            const isStep4 = this.dynamicGridComponents?.findIndex(x => x.gridStructure.componentSettings.gridName.indexOf('SLSClientSignupStep4Grid') >= 0) >= 0
                            var hasItemsInStep4Grid: boolean = false;
                            if (isStep4) {
                                // There's an intermittent issue in the rendering for Step 4 that's causing an extra grid component to be added to the page.
                                if (this.dynamicGridComponents.length === 2) {
                                    shouldRedirect = this.dynamicGridComponents[1].items.findIndex(x => x.sls_erp_type$name === '3') >= 0;
                                    hasItemsInStep4Grid = this.dynamicGridComponents[1].items.length > 0;
                                }
                                else {
                                    shouldRedirect = this.dynamicGridComponents[0].items.findIndex(x => x.sls_erp_type$name === '3') >= 0 && this.dynamicGridComponents[0].items.length > 0;
                                    hasItemsInStep4Grid = this.dynamicGridComponents[0].items.length > 0;
                                }
                            }

                            // This will handle updates to the main menu during the Post-Signup Process 
                            if (this.isPostSignupIncomplete()) {
                                this.appSession.refreshPostSignup();
                            }

                            if (isStep4 && !hasItemsInStep4Grid) {
                                this.notify.warn(this.l("NoItemsInGrid"));
                                step4Failure = true;
                            }
                            else if (shouldRedirect && isStep4) {
                                this.goToLink(data, true);
                            }
                            else if (shouldRedirect) {
                                this.goToLink(data);
                            }
                            else if (!shouldRedirect && isStep4) {
                                this.notify.warn(this.l("ERPNotYetAvailable"));
                            }
                            else if (!shouldRedirect) {
                                this.notify.success(this.l("ChangesSuccessfullyApplied"));
                            }
                        }
                    }

                    if (!step4Failure) {
                        abp.event.trigger('app.clearComponentChanges', false, true);
                        // Reset component states
                        this.disabled = this.originalDisabledValue;
                        this.userNotified = false;
                        this.saving = false;
                    }

                    if (isFromDialog) {
                        abp.event.trigger('app.clearUnsavedChanges');
                    }

                    // Force change detection
                    this.cd.markForCheck();
                }
            });
    }

    step1Activation(emailAddress: string, data) {
        let request = new SaveDynamicDataRequest();
        request.dataSourceName = "GenerateActivationCode";
        request.action = "update";
        request.data = {};

        request.data["emailAddress"] = emailAddress;

        this._dynamicDataServiceProxy.saveDynamicData(request)
            .pipe(
                finalize(() => {
                    this.goToLink(data);
                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (!result.data.items[0].isSuccess) {
                    let displayError = "";
                    result.data.items[0].validationMessage.forEach(error => {
                        displayError += error + "<br><br>";
                    });

                    this.notify.error(displayError, this.l('SendNewCode'), { isHtml: true });
                }
            });
    }

    save() {
        this.gridIsLoading();
        let request = new SaveDynamicDataRequest();
        let data = {};

        //NOTE: loop multiple grid components
        if (this.dynamicGridComponents.length > 0) {
            let gridComponents: any[] = [];

            this.dynamicGridComponents.forEach(dynamicGridComponent => {
                if (dynamicGridComponent !== undefined) {
                    let grid = {};

                    //NOTE: Assign parentId
                    if (this.retrieveDataBy !== undefined) {
                        dynamicGridComponent.items.map(x => {
                            x.parentId = this.retrieveDataBy;
                        });
                    }

                    //TODO: Add support for multiple grid returnedData
                    if (dynamicGridComponent.gridStructureDataSourceName !== "") {
                        grid['gridName'] = dynamicGridComponent.gridStructureDataSourceName;
                    }
                    else {
                        grid['gridName'] = dynamicGridComponent.gridStructure.componentSettings.gridName;
                    }
                    grid['gridId'] = dynamicGridComponent.gridStructure.componentSettings.gridId;
                    grid['gridStructure'] = dynamicGridComponent.gridStructure;
                    grid['gridDataDataSourceName'] = dynamicGridComponent.gridDataDataSourceName;

                    grid['items'] = dynamicGridComponent.items;
                    grid['deletedIds'] = dynamicGridComponent.deletedIds;
                    grid['addedAndUpdatedItems'] = dynamicGridComponent.addedItems.concat(dynamicGridComponent.updatedItems);

                    gridComponents.push(grid);
                }
            });

            data['gridComponents'] = gridComponents;
        }

        //NOTE: loop multiple form components
        if (this.dynamicFormComponents.length > 0) {
            let formComponents: any[] = [];

            this.dynamicFormComponents.forEach(dynamicFormComponent => {
                if (dynamicFormComponent !== undefined) {
                    let form = {};

                    form['formStructure'] = dynamicFormComponent.formStructure;
                    form['formName'] = dynamicFormComponent.formStructureDataSourceName;
                    form['value'] = dynamicFormComponent.dynamicForm?.value;

                    formComponents.push(form);
                }
            });

            data['formComponents'] = formComponents;
        }

        request.action = "save";
        request.dataSourceName = this.pageName;
        request.data = data;

        console.log("request");
        console.log(request);
        // this.gridIsLoading(false);
        // return;

        this._dynamicDataServiceProxy.saveDynamicData(request)
            .pipe(
                catchError(() => {
                    return of(undefined);
                }),
                finalize(() => {
                    this.gridIsLoading(false);
                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (result.data && Object.keys(result.data).length === 0 && Object.getPrototypeOf(result.data) === Object.prototype) {
                    this.notify.success(this.l("ChangesSuccessfullySaved"));
                }

                if (result.data?.isSuccess) {
                    this.dynamicGridComponents.forEach(dynamicGridComponent => {
                        if (dynamicGridComponent !== undefined) {
                            if (result.data.returnedData.length > 0) {

                                const filteredItems = dynamicGridComponent.items.filter(x => x.id !== 0);
                                dynamicGridComponent.items = filteredItems;

                                result.data.returnedData.forEach(returnedItem => {
                                    for (var key in returnedItem) {
                                        if (key.toLowerCase().includes("date") == true && key.includes("Id") == false) {
                                            returnedItem[key] = returnedItem[key] ? DateTime.fromISO(returnedItem[key]) : <any>undefined;
                                        }
                                    }

                                    dynamicGridComponent.items.push(returnedItem);
                                    dynamicGridComponent.addedItems = [];
                                });
                            }

                            dynamicGridComponent.disableActionButton = false;

                            dynamicGridComponent.updatedItems = [];

                            dynamicGridComponent.populateGrid();
                        }
                    });

                    this.dynamicFormComponents.forEach(dynamicFormComponent => {
                        if (dynamicFormComponent !== undefined) {
                            dynamicFormComponent.dynamicForm?.markAsPristine();
                        }
                    });

                    if (result.data.validationMessage.length > 0) {
                        result.data.validationMessage.forEach(message => {
                            this.notify.warn(this.l(message));
                        });
                    }
                    else {
                        this.notify.success(this.l("ChangesSuccessfullySaved"));
                    }
                }
            });
    }

    checkGridAndForms() {
        // Button should always be enabled when it's redirecting from Step 4.
        const isStep4 = this.dynamicGridComponents?.findIndex(x => x.gridStructure.componentSettings.gridName.indexOf('SLSClientSignupStep4Grid') >= 0) >= 0
        if (isStep4) {
            return false;
        }

        // Mark all forms in the page as dirty if a dirty form exists in the page.
        if (this.dynamicFormComponents.findIndex(x => x.dynamicForm.dirty) > -1) {
            this.dynamicFormComponents.forEach(x => x.dynamicForm.markAsDirty());
        }

        let validGrids = this.dynamicGridComponents?.filter(x => x.disableActionButton === false);

        // Modified these checks to consider pristine forms as invalid
        let validUndirtyForms = this.dynamicFormComponents?.filter(x => (x.dynamicForm?.valid === true || x.dynamicForm?.disabled === true) && x.dynamicForm?.dirty === true);
        let validForms = this.dynamicFormComponents?.filter(x => (x.dynamicForm?.valid === true || x.dynamicForm?.disabled === true) && x.dynamicForm?.dirty === true);

        // validForms' length needs to be the same as all the dynamicFormComponents because all their required inputs needs to be set in the page before the button can be enabled.
        if ((this.dynamicGridComponents && this.dynamicGridComponents.length > 0 && validGrids.length > 0) && (this.dynamicFormComponents && this.dynamicFormComponents.length > 0)) {
            if (validForms.length === this.dynamicFormComponents.length) {
                if (!this.userNotified) {
                    abp.event.trigger("app.notifyTabChange");
                    this.userNotified = true;
                }

                return false;
            }
            else if (validGrids.length > 0 && this.dynamicFormComponents.every(x => x.dynamicForm.valid || x.dynamicForm.disabled)) {
                if (!this.userNotified) {
                    abp.event.trigger("app.notifyTabChange");
                    this.userNotified = true;
                }

                return false;
            }
        }
        else if (this.dynamicGridComponents && this.dynamicGridComponents.length > 0 && validGrids.length > 0) {
            if (!this.userNotified) {
                abp.event.trigger("app.notifyTabChange");
                this.userNotified = true;
            }

            return false;
        }
        else if (this.dynamicFormComponents && this.dynamicFormComponents.length > 0) {
            if (validForms.length === this.dynamicFormComponents.length) {
                if (!this.userNotified) {
                    abp.event.trigger("app.notifyTabChange");
                    this.userNotified = true;
                }

                return false;
            }
        }

        if (validUndirtyForms.length === this.dynamicFormComponents.length) {
            abp.event.trigger("app.toggleTabAccessibility", false);
        }
        else {
            abp.event.trigger("app.toggleTabAccessibility", true);
        }

        return true;
    }

    isDisabled(): boolean {
        // Add saving check first to ensure button stays disabled during save operation
        if (this.saving) {
            return true;
        }

        // Check if any forms are dirty or if any grids have changes
        const hasFormChanges = this.dynamicFormComponents?.some(x => x.dynamicForm?.dirty);
        const hasGridChanges = this.dynamicGridComponents?.some(x => !x.disableActionButton);

        // If no changes in forms or grids, button should be disabled
        if (!hasFormChanges && !hasGridChanges) {
            return true;
        }

        return this.disabled || this.checkGridAndForms();
    }
}
